<template>
  <div class="MyAssResultLists">
    <!-- 360考核申请 -->
    <van-nav-bar :title="title"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
      <template #right>
        <div v-if="is360 != 'true'">{{ lastscore }}{{$t('jxMyAssResultsList.arlTxt1')}}({{ khres }})</div>
      </template>
    </van-nav-bar>
    <div class="content">
      <van-pull-refresh v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  :finished-text="$t('module.noMore')"
                  @load="onLoadAwait">
          <div v-if="is360 == 'true'">
            <div class="zb_title"
                 v-for="(it, ind) in assessList"
                 :key="ind">
              <div class="til_div">{{ it.name }}</div>
              <div class="zb_group"
                   v-for="(item, index) in it.origin"
                   :key="index">
                <van-cell-group>
                  <van-cell class="top_cell"
                            center
                            :title="
                      is360 == 'true' ? item.indicatorname : item.itemname
                    "
                            :value="is360 == 'true' ? item.typename : item.itemtype" />
                  <van-cell class="btm_cell"
                            center>
                    <template #title>
                      <span v-if="is360 == 'true'"
                            class="custom-title">{{
                        item.indicatordesp
                      }}</span>
                      <span v-else
                            class="custom-title">{{
                        item.itemdesc
                      }}</span>
                    </template>
                    <template #label>
                      <span v-if="is360 == 'true'"
                            class="custom-score">{{
                        item.score
                      }}</span>
                      <span v-else
                            class="custom-score">{{
                        item.tgvalue
                      }}</span>
                      {{$t('jxMyAssResultsList.arlTxt1')}}
                    </template>
                  </van-cell>
                </van-cell-group>
              </div>
            </div>
          </div>
          <!-- KPI考核结果 -->
          <div v-else>
            <van-collapse v-model="activeNames1"
                          class="first_coll">
              <van-collapse-item v-for="(it, ind) in assessList"
                                 :key="ind"
                                 :title="it.name"
                                 :name="ind">
                <van-collapse v-model="activeNames2"
                              class="second_coll">
                  <van-collapse-item v-for="(item, idx) in it.origin"
                                     :key="idx"
                                     :name="ind + 'c' + idx">
                    <template #title>
                      <div class="second_title">
                        <div class="second_name">{{ item.itemname }}</div>
                        <van-tag plain
                                 v-if="
                            !item.iszbss &&
                            cansj == 'true' &&
                            sjtype == '按指标申诉'
                          "
                                 type="primary"
                                 size="medium"
                                 style="margin-right: 5px"
                                 :color="'#2b8df0'"
                                 @click="toShensu(item)">{{$t('jxMyAssResultsList.arlTxt2')}}</van-tag>
                        <div class="second_score">{{ item.totalscore }}{{$t('jxMyAssResultsList.arlTxt1')}}</div>
                      </div>
                    </template>
                    <!-- 内容 -->
                    <div class="second_details">
                      <div class="thrid_desc">
                        <div class="itemdesc">{{ item.itemdesc }}</div>
                        <div class="desc">
                          <div class="thrid_per">{{$t('jxMyAssResultsList.arlTxt3')}}{{ item.itemper }}</div>
                          <div class="thrid_tg">{{ item.tgvalue }}{{$t('jxMyAssResultsList.arlTxt1')}}</div>
                        </div>
                      </div>
                    </div>
                    <!-- 评分规则 -->
                    <van-collapse class="thrid_coll"
                                  v-model="activeNames3"
                                  @change="item.showgz = !item.showgz">
                      <van-collapse-item :name="ind + 'gz' + idx"
                                         :title="$t('jxMyAssResultsList.arlTxt4')">
                        <template #right-icon>
                          <div class="right-icon">
                            {{ `${item.showgz ? $t('jxMyAssResultsList.arlTxt5') : $t('jxMyAssResultsList.arlTxt6')}` }}
                            <van-icon :name="item.showgz ? 'arrow-up' : 'arrow-down'" />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.pfdesp }}</div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- 统计项目 -->
                    <van-collapse class="thrid_coll"
                                  v-model="activeNames4"
                                  @change="item.showtj = !item.showtj">
                      <van-collapse-item :name="ind + 'tj' + idx"
                                         :title="$t('jxMyAssResultsList.arlTxt7')">
                        <template #right-icon>
                          <div class="right-icon">
                            {{ `${item.showtj ? $t('jxMyAssResultsList.arlTxt5') : $t('jxMyAssResultsList.arlTxt6')}` }}
                            <van-icon :name="item.showtj ? 'arrow-up' : 'arrow-down'" />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">
                            <div class="Sta_poj"
                                 v-for="(el, eid) in item.child"
                                 :key="eid">
                              <div>{{ el.itemname }}</div>
                              <div>{{ el.itemvalue }}</div>
                            </div>
                          </div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- 自我描述 -->
                    <van-collapse class="thrid_coll"
                                  v-model="activeNames5"
                                  @change="item.showzw = !item.showzw"
                                  v-if="is_zp == 'true'">
                      <van-collapse-item :name="ind + 'zw' + idx"
                                         :title="$t('jxMyAssResultsList.arlTxt8')">
                        <template #right-icon>
                          <div class="right-icon">
                            {{ `${item.showzw ? $t('jxMyAssResultsList.arlTxt5') : $t('jxMyAssResultsList.arlTxt6')}` }}
                            <van-icon :name="item.showzw ? 'arrow-up' : 'arrow-down'" />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.zpdesc }}</div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- 直接上级描述 -->
                    <van-collapse class="thrid_coll"
                                  v-model="activeNames6"
                                  @change="item.zjdespstatus = !item.zjdespstatus"
                                  v-if="is_zjp == 'true'">
                      <van-collapse-item :name="ind + 'zw' + idx"
                                         :title="$t('jxMyAssResultsList.arlTxt9')">
                        <template #right-icon>
                          <div class="right-icon">
                            {{ `${item.zjdespstatus ? $t('jxMyAssResultsList.arlTxt5') : $t('jxMyAssResultsList.arlTxt6')}` }}
                            <van-icon :name="
                                item.zjdespstatus ? 'arrow-up' : 'arrow-down'
                              " />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.zjdesp }}</div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- 间接上级描述 -->
                    <van-collapse class="thrid_coll"
                                  v-model="activeNames7"
                                  @change="item.jjdespstatus = !item.jjdespstatus"
                                  v-if="is_jjp == 'true'">
                      <van-collapse-item :name="ind + 'zw' + idx"
                                         :title="$t('jxMyAssResultsList.arlTxt10')">
                        <template #right-icon>
                          <div class="right-icon">
                            {{ `${item.jjdespstatus ? $t('jxMyAssResultsList.arlTxt5') : $t('jxMyAssResultsList.arlTxt6')}` }}
                            <van-icon :name="
                                item.jjdespstatus ? 'arrow-up' : 'arrow-down'
                              " />
                          </div>
                        </template>
                        <div class="thrid_desc">
                          <div class="desp">{{ item.jjdesp }}</div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                    <!-- jjdespstatus -->
                    <!-- 启用自评/启用直接评/启用间接评 -->
                    <div class="pingjia">
                      <div class="pj_sco">
                        <div class="text"
                             style="
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            text-align: center;
                          ">
                          {{$t('jxMyAssResultsList.arlTxt11')}}
                        </div>
                        <span class="time">{{ item.realscore }}{{$t('jxMyAssResultsList.arlTxt1')}}</span>
                      </div>
                      <div class="pj_sco">
                        <div v-if="is_zp == 'true'"
                             class="text"
                             style="
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            text-align: center;
                          ">
                          {{$t('jxMyAssResultsList.arlTxt12')}}
                        </div>
                        <span v-if="is_zp == 'true'"
                              class="time">{{ item.itemscore }}{{$t('jxMyAssResultsList.arlTxt1')}}</span>
                      </div>
                      <div class="pj_sco">
                        <div v-if="is_zjp == 'true'"
                             class="text"
                             style="
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            text-align: center;
                          ">
                          {{$t('jxMyAssResultsList.arlTxt13')}}
                        </div>
                        <span v-if="is_zjp == 'true'"
                              class="time">{{ item.zjsj_score }}{{$t('jxMyAssResultsList.arlTxt1')}}</span>
                      </div>
                      <div class="pj_sco">
                        <div v-if="is_jjp == 'true'"
                             class="text"
                             style="
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            text-align: center;
                          ">
                          {{$t('jxMyAssResultsList.arlTxt14')}}
                        </div>
                        <span v-if="is_jjp == 'true'"
                              class="time">{{ item.jjsj_score }}{{$t('jxMyAssResultsList.arlTxt1')}}</span>
                      </div>
                    </div>

                    <div class="select_container">
                      <div class="select_input"
                           v-if="item.idtype == '定量'">
                        <h3>{{ $t('jxDataSocreDetail.txt20')  }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input :disabled="true"
                                 type="text"
                                 v-model="item.tgvalue" />
                        </div>
                      </div>
                      <div class="select_input"
                           v-if="item.idtype == '定量'">
                        <h3>{{ $t('jxDataSocreDetail.txt21') }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input :disabled="true"
                                 type="text"
                                 v-model="item.alarmvalue" />
                        </div>
                      </div>
                      <div class="select_input"
                           v-if="item.idtype == '定量'">
                        <h3>{{ $t('jxDataSocreDetail.txt22')  }}</h3>
                        <!--  :disabled="item.idtype == '定量' || checkflag == 2 || checkflag == 3" -->
                        <div>
                          <input :disabled="true"
                                 type="text"
                                 v-model="item.realscore" />
                        </div>
                      </div>

                    </div>
                  </van-collapse-item>
                </van-collapse>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="footer"
         v-if="is360 == 'false' && is_send_khcheck=='true' && is_khcheck=='false'">
      <van-button type="info"
                  size="small"
                  @click="resultConfirmation">{{ '结果确认'}}</van-button>
    </div>
    <van-overlay :show="show"
                 @click="show = false">
      <div style="width: 100%; height: 100%"
           class="wrapper"
           @click.stop>
        <signaturePad @getSpad="getSpad"
                      @closeSpad="closeSpad"
                      :show="show"></signaturePad>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getMy360HisRecDetail, getMyKpiHisRecDetail, upLoadBase64FileFixName, empKhResCheck } from "@api/wxjx.js";
import signaturePad from "@components/signaturePad";
export default {
  components: { signaturePad },
  data () {
    let title = this.$route.query.title;
    let is360 = this.$route.query.is360;
    let mautoid = this.$route.query.mautoid;
    let lastscore = this.$route.query.lastscore;
    let is_jjp = this.$route.query.is_jjp;
    let is_zjp = this.$route.query.is_zjp;
    let is_zp = this.$route.query.is_zp;
    let sjtype = this.$route.query.sjtype;
    let cansj = this.$route.query.cansj;
    let khname = this.$route.query.khname;
    let khres = this.$route.query.khres;
    let is_khcheck = this.$route.query.is_khcheck
    let is_send_khcheck = this.$route.query.is_send_khcheck
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      title,
      is360,
      mautoid,
      lastscore,
      is_jjp,
      is_zjp,
      is_zp,
      sjtype,
      cansj,
      khname,
      khres,
      is_khcheck,
      is_send_khcheck,
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      activeNames1: [],
      activeNames2: [],
      activeNames3: [],
      activeNames4: [],
      activeNames5: [],
      activeNames6: [],
      activeNames7: [],
      show: false,
    };
  },
  created () { },
  methods: {
    // 后退
    onClickLeft () {
      this.$router.push({
        path: "/jxMyAssResults",
        query: { is360: this.is360 },
      });
    },
    // 关闭签名弹窗
    closeSpad () {
      this.show = false;
    },
    // 签名提交的回调
    getSpad (url) {
      let data = {
        filebase64str: url,
        upflag: 2,
        moduleno: 508,
        autoid: this.mautoid,
        uploadpath: 'AttachFile',
        filename: 'kh_check_sign.png',
        fileext: 'png',
        username: this.userInfo.username
      }
      // 先保存签名
      upLoadBase64FileFixName(data).then(res => {
        if (res == 'OK')
        {
          empKhResCheck({
            autoid: this.mautoid,
            empid: this.userInfo.empid
          }).then(res => {
            if (res.data[0].info == '')
            {
              Toast(this.$t("module.submitScu"));
              this.show = false;
            } else
            {
              Toast(res.data[0].info);
            }
          })
        } else
        {
          Toast(res);
        }
      })

    },
    // 结果确认
    resultConfirmation () {
      this.show = true;
    },
    // 查看我的某个360考核方案明细接口
    getMy360HisRec () {
      let _this = this;
      getMy360HisRecDetail({
        mautoid: this.mautoid,
      }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          let ary = [];
          let afterData = [];
          let tempArr = [];
          let resData = res.data;
          // let ary = resData.filter(item => { return item.pjr_empname + item.checkflagname })
          if (resData.length > 0)
          {
            resData.forEach((ele) => {
              ele.name = ele.pjr_empname + "-" + ele.checkflagname;
              ary.push(ele);
            });
            for (let i = 0; i < ary.length; i++)
            {
              if (tempArr.indexOf(ary[i].name) === -1)
              {
                afterData.push({
                  name: ary[i].name,
                  origin: [ary[i]],
                });
                tempArr.push(ary[i].name);
              } else
              {
                for (let j = 0; j < afterData.length; j++)
                {
                  if (afterData[j].name == ary[i].name)
                  {
                    afterData[j].origin.push(ary[i]);
                    break;
                  }
                }
              }
            }
          }
          _this.assessList = afterData;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    toShensu (item) {
      console.log(item, "itemitem");
      // console.log(this.khname, "this.khname.khnameitem");
      this.$router.push({
        path: "/jxMyAssResultsAppeal",
        query: {
          is360: this.is360,
          mautoid: item.autoid,
          lastscore: item.totalscore,
          khres: item.itemname,
          khtimename: this.title,
          planname: this.khname,
          sstype: this.sjtype,
          isshensu: true,
          itemautoid: this.mautoid,
          tgvalue: item.tgvalue,
          itemper: item.itemper,
          realscore: item.realscore
        },
      });
    },
    // 查看我的某个KPI考核方案明细接口
    getMyKpiHisRec () {
      let _this = this;
      getMyKpiHisRecDetail({
        mautoid: this.mautoid,
      }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          let ary = [];
          let afterData = [];
          let tempArr = [];
          let resData = res.data;
          let tjList = res.itemlist;
          // let ary = resData.filter(item => { return item.pjr_empname + item.checkflagname })
          if (resData.length > 0)
          {
            resData.forEach((ele, idx) => {
              ele.name = ele.itemtype;
              // + '-' + ele.itemname
              ele.itemdesc = ele.itemdesc
                ? ele.itemdesc.replace(/\\n/g, "\n")
                : "";
              ele.showgz = false; // 显示评分规则
              ele.showtj = false; // 显示统计项目
              ele.showzw = false; // 显示自我描述
              ele.zjdespstatus = false; // 直接上级描述
              ele.jjdespstatus = false; // 间接上级描述
              ele.child = []; // 统计项目
              if (tjList.length > 0)
              {
                tjList.forEach((it) => {
                  if (ele.itemautoid == it.itemautoid)
                  {
                    ele.child.push(it);
                  }
                });
              }
              ary.push(ele);
              this.activeNames1.push(idx);
            });
            for (let i = 0; i < ary.length; i++)
            {
              if (tempArr.indexOf(ary[i].name) === -1)
              {
                afterData.push({
                  name: ary[i].name,
                  origin: [ary[i]],
                });
                tempArr.push(ary[i].name);
              } else
              {
                for (let j = 0; j < afterData.length; j++)
                {
                  if (afterData[j].name == ary[i].name)
                  {
                    afterData[j].origin.push(ary[i]);
                    break;
                  }
                }
              }
            }
          }
          _this.assessList = afterData;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    onLoadAwait () {
      // 异步更新数据
      if (this.isLoadingAwait)
      {
        this.assessList = [];
        this.isLoadingAwait = false;
      }
      if (this.is360 == true || this.is360 == "true")
      {
        this.getMy360HisRec();
      } else
      {
        this.getMyKpiHisRec();
      }
    },
    onRefreshAwait () {
      this.assessList = [];
      this.finishedAwait = false;
      this.loadingAwait = true;
      this.onLoadAwait();
      Toast(this.$t('module.refreshSuccessfully'));
    },
    //
    // goToDetails (item) {
    //   // this.$router.push('/jxMyAssResultsDetails')
    //   // is360: is360
    //   this.$router.push({ path: "/jxMyAssResultsDetails", query: { title: this.$route.query.title } })
    // }
  },
};
</script>

<style lang="less" scoped>
.MyAssResultLists {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/.van-nav-bar__right {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    .zb_title {
      background-color: #fff;
      margin: 20px;
      border-radius: 12px;
      .til_div {
        padding: 10px 0 0 10px;
        text-align: left;
        color: #333333;
        font-weight: bold;
        font-size: 36px;
        font-family: Source Han Sans CN;
      }
      .zb_group {
        // display: flex;
        border-left: 6px solid #2b8df0;
        margin: 30px 0;
        .van-cell-group {
          .top_cell {
            .van-cell__title {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #2b8df0;
            }
            .van-cell__value {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
          }
          .btm_cell {
            .van-cell__title {
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              .custom-title {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }
            .van-cell__label {
              text-align: right;
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              .custom-score {
                font-size: 48px;
                font-family: DIN;
                font-weight: bold;
                color: #2b8df0;
              }
            }
          }
        }
      }
    }
    .first_coll {
      padding: 20px 30px;
      /deep/.van-collapse-item {
        border-radius: 12px 12px 0 0;
        margin-bottom: 30px;
        .van-cell::after {
          border-bottom: unset;
        }
        .van-collapse-item__title {
          padding: 10px 15px;
          text-align: center;
          border-radius: 12px 12px 0 0;
          background: #2b8df0;
          .van-cell__title {
            color: #fff;
            font-weight: bold;
            font-size: 32px;
            font-family: Source Han Sans CN;
          }
          .van-icon {
            color: #fff;
          }
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            padding: 10px 20px;
            .second_coll {
              .van-collapse-item {
                border-radius: unset;
                border-bottom: 0.02667rem solid #ebedf0;
                .van-cell::after {
                  border-bottom: 0.02667rem solid #ebedf0;
                }
                .van-collapse-item__title {
                  padding: 20px 30px;
                  border-radius: unset;
                  background: #fff;
                  .van-cell__title {
                    color: #333333;
                    font-weight: unset;
                    font-size: 32px;
                    font-family: Source Han Sans CN;
                    .second_title {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .second_name {
                        width: 68%;
                        text-align: left;
                        font-weight: bold;
                      }
                      .second_score {
                        color: #2b8df0;
                        margin-right: 10px;
                      }
                    }
                  }
                  .van-icon {
                    color: #333333;
                  }
                }
                .van-collapse-item__wrapper {
                  .van-collapse-item__content {
                    .second_details {
                      margin-bottom: 16px;
                      .thrid_desc {
                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                        border-radius: 12px;
                        padding: 20px;
                        .itemdesc {
                          color: #666666;
                          font-size: 24px;
                        }
                        .desc {
                          display: flex;
                          justify-content: space-between;
                          padding: 16px 0;
                          .thrid_per {
                            color: #333333;
                          }
                          .thrid_tg {
                            color: #2b8df0;
                          }
                        }
                      }
                    }
                    .thrid_coll {
                      .van-collapse-item {
                        border-radius: unset;
                        border-bottom: unset;
                        .van-cell::after {
                          border-bottom: unset;
                        }
                        .van-collapse-item__title {
                          text-align: left;
                          padding: 0;
                          .van-cell__title {
                            color: #999999;
                            font-size: 28px;
                          }
                          .right-icon {
                            display: flex;
                            align-items: center;
                            color: #2b8df0;
                            font-size: 24px;
                            .van-icon {
                              color: #2b8df0;
                            }
                          }
                        }
                        .van-collapse-item__wrapper {
                          .van-collapse-item__content {
                            padding: 0;
                            .thrid_desc {
                              // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                              // border-radius: 12px;
                              margin: 16px 4px 4px 4px;
                              .desp {
                                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                                border-radius: 12px;
                                padding: 20px;
                                color: #666666;
                                font-size: 24px;
                                min-height: 5em;
                                .Sta_poj {
                                  padding-bottom: 12px;
                                  color: #333333;
                                  display: flex;
                                  justify-content: space-between;
                                  border-bottom: 0.02667rem solid #ebedf0;
                                }
                              }
                            }
                            // .thrid_Sta {
                            //   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
                            //   border-radius: 12px;
                            //   margin: 16px 4px 4px 4px;
                            //   min-height: 5em;

                            // }
                          }
                        }
                      }
                    }
                    .pingjia {
                      margin-bottom: 20px;
                      display: flex;
                      justify-content: space-between;
                      .pj_sco {
                        .text {
                          padding: 0 4px;
                          border: 1px solid #f53838;
                          background: #f53838;
                          border-radius: 50%;
                          color: #fff;
                        }
                        .time {
                          color: #666666;
                          padding: 0 10px;
                        }
                      }
                    }
                    .van-hairline--top-bottom::after {
                      border-width: 0;
                    }

                    .select_container {
                      width: 100%;
                      display: flex;
                      justify-content: space-around;
                      margin-top: 0.4rem;
                      .select_input {
                        width: 25%;
                        div {
                          text-align: center;
                        }
                        h3 {
                          margin: 0;
                          font-size: 28px;
                          color: #666;
                          text-align: center;
                        }
                        input {
                          width: 80%;
                          height: 66px;
                          background: #ffffff;
                          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.18);
                          border-radius: 10px;
                          border: 0;
                          margin-bottom: 6px;
                          margin-top: 12px;
                          text-align: center;
                          color: #2b8df0;
                          font-size: 36px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .van-collapse-item--border::after {
        border-top: unset;
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 80%;
      font-size: 28px;
      font-family: Source Han Sans CN;
    }
    // .f_right {
    //   width: 40%;
    //   text-align: center;
    //   font-size: 36px;
    //   font-family: Source Han Sans CN;
    //   font-weight: 500;
    //   line-height: 62px;
    //   color: #2b8df0;
    // }
  }
}

input[disabled],
input:disabled {
  background: #ddd !important;
}
</style>